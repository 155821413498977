.SimpleLoader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgb(0, 0, 0);
    opacity: 0.2;
    z-index: 2000;
  }