@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap);

html {
    position: relative;
    min-height: 100%;
    height: unset;
}

body {
    padding-bottom: 14px;
    margin-bottom: 60px;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: grayscale;
}

.ui.container h1.ui.huge.header {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    margin-top: 40px;
    font-size: 36px;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-top: 20px;
    background-color: #f5f5f5;
    margin-bottom: 0;
    margin-top: 20px;
}

footer p {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #777777;
}

p.lead {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 21px;
    color: #333333;
    line-height: 1.4;
    font-weight: 300;
}

.langBox {
    padding: 2em;
}


.ui.medium.image, .ui.medium.images .image, .ui.medium.images img, .ui.medium.images svg {
    width: 300px;
}


.MainNav {
    height: 19em;
    background-color: black;
}

.MainNav > div {
    padding-top:10em;
    color: white;
}

.MainBody {
    margin-top: 2em;
    font-size: 1.2em;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    color:black;
    line-height: 150%;
}

.MainBody .Desc {
    line-height: 200%;
}

.MainBody .Body {
    margin: 3em 0em;
}

.MainBody .Block {
    margin: 2.5em 0em;
}

.MainBody ul {
    padding-left: 1.8em;
}

.MainBody .HeadingImage {
    width: 222px;
    height: 24px;
    margin-top: 2em;
    margin-bottom: 1em;
}

.GameTitle {
    margin: 0.25em 0em;
}

.GameDesc {
    margin: 0.1em 0em;
    font-size:0.9em;
    color:gray;
}

.IconFollow {
    width: 45px;
    height: 45px;
    margin-right: 0.5em;
    margin-bottom: 3em;
}
.MdBox {
    padding: 0.8rem 0 0 0;
}

.MdBox .ButtonBox {
    margin-top: 2rem;
}
.SimpleLoader {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgb(0, 0, 0);
    opacity: 0.2;
    z-index: 2000;
  }
