html {
    position: relative;
    min-height: 100%;
    height: unset;
}

body {
    padding-bottom: 14px;
    margin-bottom: 60px;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: grayscale;
}

.ui.container h1.ui.huge.header {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    margin-top: 40px;
    font-size: 36px;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding-top: 20px;
    background-color: #f5f5f5;
    margin-bottom: 0;
    margin-top: 20px;
}

footer p {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #777777;
}

p.lead {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 21px;
    color: #333333;
    line-height: 1.4;
    font-weight: 300;
}

.langBox {
    padding: 2em;
}


.ui.medium.image, .ui.medium.images .image, .ui.medium.images img, .ui.medium.images svg {
    width: 300px;
}

